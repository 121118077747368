<template>
  <div class="player-wrap">
    <audio :data-setup="dataSetup" :key="key" v-if="type === 'audio'" ref="videoPlayer" class="video-js vjs-big-play-centered"></audio>
    <video :data-setup="dataSetup" :key="key" v-if="type === 'video'" ref="videoPlayer" class="video-js vjs-big-play-centered"></video>
  </div>
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export default {
  name: "Player",
  props: {
    dataSetup: {
      type: String,
      default() {
        return '{"fluid": true}';
      }
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    type: {
      type: String,
      default() {
        return "audio";
      }
    },
    key: {
      type: String
    }
  },
  data() {
    return {
      player: null
    }
  },
  methods: {
    init(options) {
      this.destroyPlayer();
      this.player = videojs(this.$refs.videoPlayer, options, function onPlayerReady() {
        console.log('onPlayerReady.............', this.player, options);
      })
    },
    destroyPlayer() {
      if (this.player) {
        this.player.dispose();
      }
    }
  },
  mounted() {
    this.init(this.options);
  },
  watch: {
    options: {
      handler(newVal) {
        this.init(newVal);
      },
      deep: true
    },
    type: {
      handler() {
        this.init(this.options);
      }
    }
  },
  beforeUnmount() {
    this.destroyPlayer();
  }
}
</script>
